import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet"; 
import TrioVideo from "../images/trio.mp4"
import Img01 from "../images/gallery/01.jpg"
import Img02 from "../images/gallery/02.jpg"
import Img03 from "../images/gallery/03.jpg"
import Img04 from "../images/gallery/04.jpg"
import Img05 from "../images/gallery/05.jpg"
import Img06 from "../images/gallery/06.jpg"
import Img07 from "../images/gallery/07.jpg"
import Img08 from "../images/gallery/08.jpg" 
import GMap from "../components/Map"

import { SRLWrapper } from "simple-react-lightbox";
import { ChevronRightIcon, SunIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import { Plan } from "../components/Plan";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
 
const files = [
  {name: 'Dům Trio', url: Img01 },
  {name: 'Dům Trio', url: Img02 },
  {name: 'Dům Trio', url: Img03 },
  {name: 'Dům Trio', url: Img04 },
  {name: 'Dům Trio', url: Img05 },
  {name: 'Dům Trio', url: Img06 },
  {name: 'Dům Trio', url: Img07 },
  {name: 'Dům Trio', url: Img08 },
]

const Gallery = () => {
  
  return (
    <SRLWrapper>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
          {files.map((file,index) => {
            return (
              <div className={"overflow-hidden shadow-md border hover:border-gray-500 "}>
                <img
                  className="cursor-pointer object-cover min-h-full "
                  src={file.url}
                  alt={file.name}
                />
              </div>
            )
          })}
      </div>
      </SRLWrapper>
  )
}

const IndexPage = ({location}) => {
  
  return(
  <>
       <Helmet>
      <meta charSet="utf-8" />
      <title>Slunný vršek - domy Trio</title> 
    </Helmet>
    <Layout location={location}> 
          
        <main className="-mt-24">

           
 
                  
                    <div  className=" overflow-hidden h-144 lg:h-auto  max-h-200 -mx-2 ">
                    
                      <video autoPlay playsInline muted loop className="w-full h-full object-cover ">
                          <source  src={TrioVideo} type="video/mp4" /> 
                          Your browser does not support the video tag.
                      </video>
                        
                    </div> 
                  
                  <div className="mx-auto max-w-7xl p-10 lg:p-20 lg:pt-10 -mt-20 lg:-mt-32 relative bg-white bg-opacity-80 shadow-md  border border-white overflow-hidden" >
                       

                        <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                            <polygon points="0,0 300,0 300,300" class="triangle" fill="currentColor" />
                            <SunIcon width="50" x="160" y="-70"  fill="#ffffff" />
                        </svg>
                        <div className="relative">
                          <div className="my-5 mb-10 font-merri ">
                            <div className="text-xl lg:text-3xl "><span className="font-bold">Na slunném vršku</span> Kamenice</div> 
                            <div className="    font-merri">
                                prémiové bydlení
                            </div>
                            {/* <Link to={'/planky'} className=" py-2 px-5 my-5 rounded-md inline-flex items-center  bg-green-600 text-white space-x-4 border shadow-md border-green-800 hover:bg-green-700">
                              <span>Poslední volný byt!</span><ChevronRightIcon className="w-5 h-5" />
                            </Link> */}
                          </div>
                          <Gallery />

                          <div className="my-10" ><GMap zoom={11} /></div> 
                      </div>

                      <div className="grid lg:grid-cols-2">
                        <div className="p-5" >
                          <div className="text-xl lg:text-3xl "><span  >Domy</span> <span className="text-yellow-400">Trio</span></div> 
                          <div className="text-sm py-2 text-gray-600">
                            Bytové domy Trio s osvědčeným dispozičním řešením rozdělené na 3 bytové jednotky s obytným podkrovím jednoduše rozvržené do tvaru písmene L.
                          </div>
                          <div className="grid items-center justify-center p-5 gap-4">
                              <Link to={'/planky/trio-1'}>
                              <button
                                  type="button"
                                  className="flex items-center px-6 py-3 border border-transparent shadow-sm  w-64 text-base font-medium rounded-md text-white bg-gray-600 hover:bg-yellow-400 focus:outline-none "
                              >
                                  <ChevronRightIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                                  Trio 1
                              </button>
                              </Link>
                              <Link to={'/planky/trio-2'}>
                              <button
                                  type="button"
                                  className="flex items-center px-6 py-3 border border-transparent shadow-sm  w-64 text-base font-medium rounded-md text-white bg-gray-600 hover:bg-yellow-400 focus:outline-none "
                              >
                                  <ChevronRightIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                                  Trio 2
                              </button>
                              </Link>
                              <Link to={'/planky/trio-3'}>
                              <button
                                  type="button"
                                  className="flex items-center px-6 py-3 border border-transparent shadow-sm  w-64 text-base font-medium rounded-md text-white bg-gray-600 hover:bg-yellow-400 focus:outline-none "
                              >
                                  <ChevronRightIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                                  Trio 3
                              </button>
                              </Link>
                          </div>
                        </div>
                        <div className="relative w-full max-w-lg"> 
                                <Plan />
                        </div>
                    </div>
                    </div>
                    <div className=" w-full justify-center  py-5 bg-white text-gray-400 text-sm border max-w-7xl mx-auto shadow-md">
                      <div className="text-center  mb-2">Partneři</div>
                        <div className="grid grid-cols-2 gap-6 md:grid-cols-4 lg:grid-cols-5 w-full justify-center ">
                          
                            <div className="flex items-center justify-center"><StaticImage src={'../images/unicredit.svg'} className="w-32" /></div>
                            <div className="flex items-center justify-center"><a href="https://www.moebelix.cz/" target="_blank"><StaticImage src={'../images/mobelix.png'} className="w-20" /></a></div>
                            <div className="flex items-center justify-center"><a href="https://www.peknydesign.com/" target="_blank"><StaticImage src={'../images/peknydesign.webp'} className="w-20" /></a></div>
                            <div className="flex items-center justify-center"><a href="https://www.hoffmann.cz/" target="_blank"><StaticImage src={'../images/hoffmann.png'} className="w-20" /></a></div>
                            <div className="flex items-center justify-center"><a href="https://www.acema.cz/" target="_blank"><StaticImage src={'../images/acema.png'} className="w-20" /></a></div>
                            <div className="flex items-center justify-center"><a href="https://www.maxeco.info/" target="_blank"><StaticImage src={'../images/maxeco.png'} className="w-20" /></a></div>
                            <div className="flex items-center justify-center"><a href="https://www.jvpohoda.cz/" target="_blank"><StaticImage src={'../images/jvpohoda.svg'} className="w-20" /></a></div>

                        </div>
                    </div>
 
      </main>
    </Layout>
  </>
)}

export default IndexPage
 