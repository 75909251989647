import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React  from "react"


export const Plan = () => {
    return (
        <>
                <StaticImage src={'../images/letecka.jpg'} className="w-full h-full max-w-2xl object-fill border filter saturate-50 opacity-80" />
                <div className="absolute w-full" style={{left:"17%", top:"22%"}}>
                    <Link to={'/planky/trio-1'} >
                    <svg viewBox="-80 20 200 200" className="w-1/6 top-0 left-0 absolute text-yellow-400 hover:text-yellow-200 transition duration-300 ease-in-out" style={{left:"20%"}} fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                        <g transform = "translate(100, 100) rotate(115 0 0)">  
                            <rect width="125" height="55"  />
                            <rect width="55" height="125"  />
                        </g>
                        <text x="35" y="130" className="text-5xl text-black opacity-60 font-bold" >1</text>
                    </svg>
                    </Link>
                    <Link to={'/planky/trio-2'} >
                    <svg viewBox="-80 20 200 200" className="w-1/6  absolute text-yellow-400 hover:text-yellow-200 transition duration-300 ease-in-out" style={{left:"14%", marginTop:"16%"}} fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                        <g transform = "translate(40, 200) rotate(203 0 0)">  
                            <rect width="125" height="55"  />
                            <rect width="55" height="125"  />
                        </g>
                        <text x="10" y="165" className="text-5xl text-black opacity-60 font-bold" >2</text>
                    </svg>
                    </Link>
                    <Link to={'/planky/trio-3'} >
                    <svg viewBox="-80 20 200 200" className="w-1/6  absolute text-yellow-400 hover:text-yellow-200 transition duration-300 ease-in-out" style={{left:"9%", marginTop:"33%"}} fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                        <g transform = "translate(40, 200) rotate(203 0 0)">  
                            <rect width="125" height="55"  />
                            <rect width="55" height="125"  />
                        </g>
                        <text x="10" y="170" className="text-5xl text-black opacity-60 font-bold" >3</text>
                    </svg>
                    </Link>
                </div>
        </>
    )
}